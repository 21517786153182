<template>
  <!-- <div class="index"> -->
  <div class="index" style="height: 100%">
    <div class="nav_top flex justify-between align-center" :class="{ bggreen: isVideo }">
      <div class="top_menu" @click="showLefts">
        <img src="../assets/top_menu.png" class="top_menu_img" alt="" />
      </div>
      <div class="top_title flex justify-center align-center">
        <img src="../assets/logo.png" class="top_menu_logo" alt="" />
      </div>
      <!-- <div class="title_text">春之唤</div> -->
      <!-- <div class="top_search" @click="goClass">
        <img src="../assets/top_search.png" class="top_menu_search" alt="" />
      </div> -->
      <div class="top_search" @click="showSearchBtn">
        <img src="../assets/top_search.png" class="top_menu_search" alt="" />
      </div>
    </div>

    <div class="search-block" v-if="showSearch">
      <div class="search-mask" @click="showSearchBtn"></div>
      <div class="search-top-block">
        <!-- <div class="search-top-type flex align-center" v-if="is_home"> -->
        <div class="search-top-type flex align-center" v-if="isIndex || isAbout || isClass || isHonor || isVip || isOther">
          <div class="search-type-item" :class="choose_type == 'type_1' ? 'active' : ''" @click="chooseSearchType('type_1')">全系产品</div>
          <div class="search-type-item" :class="choose_type == 'type_2' ? 'active' : ''" @click="chooseSearchType('type_2')">产品使用</div>
          <!-- <div class="search-type-item" :class="choose_type == 'type_1' ? 'active' : ''" @click="goIndex">首页</div> -->
          <!-- <div class="search-type-item" :class="choose_type == 'type_2' ? 'active' : ''" @click="goAbout">关于春之唤</div> -->
          <!-- <div class="search-type-item" :class="choose_type == 'type_3' ? 'active' : ''" @click="goProduce">全系列产品</div>
          <div class="search-type-item" :class="choose_type == 'type_4' ? 'active' : ''" @click="goClass">产品使用</div> -->
          <!-- <div class="search-type-item" :class="choose_type == 'type_4' ? 'active' : ''" @click="goActive">品牌动态</div> -->
          <!-- <div class="search-type-item" :class="choose_type == 'type_4' ? 'active' : ''" @click="goVipCenter">会员中心</div> -->
        </div>
        <div class="search-input-block flex align-center justify-between">
          <input type="text" class="search-input-wrap" v-model="searchText" placeholder="请输入" maxlength="100" @keyup.enter="searchHandle" @input="search($event)"/>
          <div class="search-input-btn" @click="searchHandle">搜索</div>
        </div>
        <div class="search-input-num">{{ inputNumCount }}</div>
      </div>
    </div>

    <div :class="{ nav_mask: showMask }" @click="showLefts"></div>
    <div class="nav_left" :class="{ active: showLeft, backactive: backLeft }">
      <div class="nav_left_item_img flex justify-center align-center" @click="goIndex">
        <img src="../assets/logo.png" class="left_item_logo" alt="" />
      </div>
      <div class="nav_left_item" @click="goIndex">首页</div>
      <div class="nav_left_item_case flex align-center justify-between" :class="{ hovers: aboutList }" @click="showAboutList">
        <div class="nav_left_item_left">关于春之唤</div>
        <div class="nav_left_item_right" v-if="aboutList">-</div>
        <div class="nav_left_item_right" v-else>+</div>
      </div>
      <div class="nav_left_item_ul" v-if="aboutList">
        <ul>
          <li>
            <div class="nav_left_item_li flex justify-between align-center" @click="goAbout">
              <div class="nav_left_item_li_text">品牌故事</div>
            </div>
          </li>
          <li>
            <div class="nav_left_item_li flex justify-between align-center" @click="goGrass">
              <div class="nav_left_item_li_text">探索本草嫩肤</div>
            </div>
          </li>

        </ul>
      </div>
      <div class="nav_left_item" @click="goProduce">全系产品</div>
      <div class="nav_left_item_case flex align-center justify-between" :class="{ hovers: classList }" @click="showClassList">
        <div class="nav_left_item_left">产品使用</div>
        <div class="nav_left_item_right" v-if="classList">-</div>
        <div class="nav_left_item_right" v-else>+</div>
      </div>
      <div class="nav_left_item_ul" v-if="classList">
        <ul>
          <li>
            <div class="nav_left_item_li flex justify-between align-center" @click="goClass">
              <div class="nav_left_item_li_text">视频教程</div>
            </div>
          </li>
          <li>
            <div class="nav_left_item_li flex justify-between align-center" @click="goWord">
              <div class="nav_left_item_li_text">护肤知识</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="nav_left_item" @click="goHonor">品牌荣誉</div>
      <div class="nav_left_item" @click="goActive">品牌动态</div>
    </div>
    <router-view ref="searchInput" @isIndex="changeTopTab" @isAbout="changeTopTab" @isProduce="changeTopTab" @isClass="changeTopTab" @isVideo="changeTopTab" @isHonor="changeTopTab" @isVip="changeTopTab" @isOther="changeTopTab"></router-view>

    <div class="go-top" @click="goBackTop">
      <img src="../assets/back_top.png" class="top-img" alt="" />
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "Index",

  data() {
    return {
      showMask: false, // 左边栏遮罩
      showLeft: false, // 滑动左边栏出现
      aboutList: false, // 是否显示 关于春之唤 下拉列表
      classList: false, // 是否显示 产品使用 下拉列表
      backLeft: true, // 滑动左边栏消失
      showSearch: false, // 搜索模块
      searchText: "", // 搜索框内容
      isbackground: false,
      // tabBottom: false,
      isIndex: false, //是否是主页
      isAbout: false, // 是否是关于春之唤
      isProduce: false, // 是否是全系产品
      isClass: false, // 是否是产品使用
      isVideo: false, // 是否是视频
      isHonor: false, // 是否是活动
      isVip: false, // 是否是会员中心
      isOther: false,
      choose_type: "type_1", // 搜索类别
      inputNumCount: 100, // 输入剩余文字
      // searchText: "",
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
  },

  created() {
    // this.checkMobile();
    // this.goBackTop();
  },

  methods: {

    // 判断是否是移动端
    checkMobile() {
      this.flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      // console.log(this.flag);
      if (this.flag === null) {
        // console.log("pc端");
        // this.rightNavShow = true;
        // let url = window.location.protocol + "//"+window.location.host + '/teach'; //我的移动端页面是在h5里面的所以这里添加了后面这一段'/h5/index.html'
        // window.location.href = 'http://www.chunzhihuan.com'; //如果有实际移动端域名网址的也可以忽略上面一段，直接输入移动端网址进行跳转就可以了
      } else {
        // console.log("移动端");
        // this.rightNavShow = false;
      }
    },

    // 获取项目配置
    // async getAllConfig() {
    //   let data = await getConfig({});
    //   document.title = data.website_name;
    //   // if (data.length) this.artInfo = data[0]
    //   // this.isShow = false
    // },

    //   显示左边栏
    showLefts(event) {
      if (!this.showLeft) {
        this.showLeft = true;
        this.backLeft = false;
        this.showMask = true;
      } else {
        this.showLeft = false;
        this.backLeft = true;
        this.showMask = false;
      }
    },

    // 显示 关于春之唤
    showAboutList() {
      if (!this.aboutList) {
        this.aboutList = true;
      } else {
        this.aboutList = false;
      }
    },

    // 显示 产品使用
    showClassList() {
      if (!this.classList) {
        this.classList = true;
      } else {
        this.classList = false;
      }
    },

// 显示 搜索栏
    showSearchBtn() {
      if (this.showSearch) {
        this.showSearch = false;
      } else {
        // if ( this.$route.path === "/companyProduce" || this.$route.path === "/companyClass"
        //   // this.$route.path === "/medium" ||
        //   // this.$route.path === "/videos"
        // ) {
        //   this.is_home = false;
        // }
        this.showSearch = true;
      }
    },

    // 搜索栏类别选择
    chooseSearchType(type) {
      this.choose_type = type;
    },

    // 回车搜索
    searchHandle(event) {
      // console.log("232425")
      if (this.searchText == "") {
        return false
        // if (this.isIndex) {
        //   return;
        // } else {
        //   // this.searchGoPage();
        //   // this.goPageSearch()
        // }
      } else {
        // isIndex || isAbout || isClass || isHonor || isVip || isOther
        if(this.isIndex || this.isAbout || this.isClass || this.isHonor || this.isVip || this.isOther) {
          if(this.choose_type == 'type_1') {
            // console.log("dfds")
            this.goProduce()
            setTimeout(()=>{
              this.$refs.searchInput.getMoreList(this.searchText)
            },500)
            
            // this.changeTopTab()
            // console.log(this.$root)
            // this.$root.eventHub.$emit('produce',{searchkey: this.searchText});
            // console.log("dsdas")
            // this.$nextTick(() => {
            //   this.$refs.childRouter.getMoreList(this.searchText)
            // })
          } else if(this.choose_type == 'type_2') {
            this.goClass()
            setTimeout(()=>{
              this.$refs.searchInput.getMoreList(this.searchText)
            },600)
            // this.$refs.searchInput.getMoreList(this.searchText)
            // this.$nextTick(() => {
            //   this.$refs.childRouter.getMoreList(this.searchText)
            // })
          }
        } else {
          if(this.$route.path === "/companyProduce") {
            setTimeout(()=>{
              this.$refs.searchInput.getMoreList(this.searchText)
            },500)
            // this.$refs.searchInput.getMoreList(this.searchText)
          } else if(this.$route.path === "/companyClass") {
            setTimeout(()=>{
              this.$refs.searchInput.getMoreList(this.searchText)
            },600)
            // this.$refs.searchInput.getMoreList(this.searchText)
          }
        }
        
        // if (this.isIndex) {
        //   // this.goPageSearch();
        // } else {
        //   // this.searchGoPage();
        // }
      }
    },

    // 搜索输入
    search(event) {
      // console.log(event.currentTarget.value)
      this.inputNumCount = 100 - event.currentTarget.value.length;
    },

    // 子路由页面传来的参数
    changeTopTab(data){
      // console.log(data)
      // console.log(this.$root.eventHub)
            // this.$root.eventHub.$emit('produce',{searchkey: this.searchText});
      if(data === "index") {
        this.isIndex = true; //是否是主页
        this.isAbout = false;// 是否是关于春之唤
        this.isProduce = false; // 是否是全系产品
        this.isClass = false; // 是否是产品使用
        this.isVideo = false; // 是否是视频
        this.isHonor = false; // 是否是活动
        this.isVip = false; // 是否是会员中心
      } else if(data === "about") {
        this.isIndex = false; //是否是主页
        this.isAbout = true;// 是否是关于春之唤
        this.isProduce = false; // 是否是全系产品
        this.isClass = false; // 是否是产品使用
        this.isVideo = false; // 是否是视频
        this.isHonor = false; // 是否是活动
        this.isVip = false; // 是否是会员中心
      } else if(data === "produce") {
        this.isIndex = false; //是否是主页
        this.isAbout = false;// 是否是关于春之唤
        this.isProduce = true; // 是否是全系产品
        this.isClass = false; // 是否是产品使用
        this.isVideo = false; // 是否是视频
        this.isHonor = false; // 是否是活动
        this.isVip = false; // 是否是会员中心
      } else if(data === "class") {
        this.isIndex = false; //是否是主页
        this.isAbout = false;// 是否是关于春之唤
        this.isProduce = false; // 是否是全系产品
        this.isClass = true; // 是否是产品使用
        this.isVideo = false; // 是否是视频
        this.isHonor = false; // 是否是活动
        this.isVip = false; // 是否是会员中心
      } else if(data === "video") {
        this.isIndex = false; //是否是主页
        this.isAbout = false;// 是否是关于春之唤
        this.isProduce = false; // 是否是全系产品
        this.isClass = false; // 是否是产品使用
        this.isVideo = true; // 是否是视频
        this.isHonor = false; // 是否是活动
        this.isVip = false; // 是否是会员中心
      } else if(data === "honor") {
        this.isIndex = false; //是否是主页
        this.isAbout = false;// 是否是关于春之唤
        this.isProduce = false; // 是否是全系产品
        this.isClass = false; // 是否是产品使用
        this.isVideo = false; // 是否是视频
        this.isHonor = true; // 是否是活动
        this.isVip = false; // 是否是会员中心
      } else if(data === "vip") {
        this.isIndex = false; //是否是主页
        this.isAbout = false;// 是否是关于春之唤
        this.isProduce = false; // 是否是全系产品
        this.isClass = false; // 是否是产品使用
        this.isVideo = false; // 是否是视频
        this.isHonor = false; // 是否是活动
        this.isVip = true; // 是否是会员中心
      } else if (data === "other") {
        this.isIndex = false; //是否是主页
        this.isAbout = false;// 是否是关于春之唤
        this.isProduce = false; // 是否是全系产品
        this.isClass = false; // 是否是产品使用
        this.isVideo = false; // 是否是视频
        this.isHonor = false; // 是否是活动
        this.isVip = false; // 是否是会员中心
      }
      this.goBackTop();
      this.checkMobile();
    },

    // 进入首页
    goIndex() {
      this.showLeft = false;
      this.backLeft = true;
      this.showMask = false;
      this.classList = false;
      this.aboutList = false;
      router.replace({
        path: "/homePage",
      });
      // this.goBackTop();
    },

    // 进入关于春之唤
    goAbout() {
      this.showLeft = false;
      this.backLeft = true;
      this.showMask = false;
      this.classList = false;
      this.aboutList = false;
      router.replace({
        path: "/companyAbout",
      });
      // this.goBackTop();
    },

    // 进入探寻本草嫩肤
    goGrass() {
      this.showLeft = false;
      this.backLeft = true;
      this.showMask = false;
      this.classList = false;
      this.aboutList = false;
      router.replace({
        path: "/companyGrass",
      });
      // this.goBackTop();
    },

    // 进入全系产品
    goProduce() {
      this.showLeft = false;
      this.backLeft = true;
      this.showMask = false;
      this.classList = false;
      this.aboutList = false;
      router.replace({
        path: "/companyProduce",
      });
      // this.goBackTop();
    },

    // 进入产品使用
    goClass(){
      this.showLeft = false;
      this.backLeft = true;
      this.showMask = false;
      this.classList = false;
      this.aboutList = false;
      router.replace({
        path: "/companyClass"
      });
      // this.goBackTop();
    },
    // 进入产品使用
    goWord(){
      this.showLeft = false;
      this.backLeft = true;
      this.showMask = false;
      this.classList = false;
      this.aboutList = false;
      router.replace({
        path: "/classWord"
      });
      // this.goBackTop();
    },

    // 进入品牌动态
    goActive() {
      this.showLeft = false;
      this.backLeft = true;
      this.showMask = false;
      this.classList = false;
      this.aboutList = false;
      router.replace({
        path: "/companyActivity",
      });
      // this.goBackTop();
    },

    // 进入品牌动态
    goHonor() {
      this.showLeft = false;
      this.backLeft = true;
      this.showMask = false;
      this.classList = false;
      this.aboutList = false;
      router.replace({
        path: "/companyHonor",
      });
      // this.goBackTop();
    },

    // 进入会员中心
    goVipCenter(){
      this.showLeft = false;
      this.backLeft = true;
      this.showMask = false;
      this.classList = false;
      this.aboutList = false;
      router.replace({
        path: "/companyVip",
      });
      // this.goBackTop();
    },

    // 页面滚动
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; // 滚动条偏移量
        // console.log(scrollTop)
      if (scrollTop > 30) {
        this.isbackground = true;
      } else {
        this.isbackground = false;
      }
    },

    // 回到顶部
    goBackTop() {
      // console.log("ggggggg");
      // console.log(document.getElementsByClassName("index"))
      
      document.getElementsByClassName("index")[0].scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style scoped>
.index {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav_top {
  width: 90%;
  height: 7.5vh;
  padding: 0.5vh 5%;
  position: relative;
  top: 0;
  left: 0;
  position: fixed;
  background: #ffffff;
  z-index: 11000;
  /* border-bottom: 2px solid #f5f5f5; */
}

.nav_top.bggreen {
  background: #eafcfc !important;
}

.nav_mask {
  position: absolute;
  width: 100%;
  height: 100%;
  /* height: 91.5vh; */
  /* top: 60px; */
  top: 8.5vh;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.03); */
  z-index: 9999;
}

.top_menu {
  width: 30px;
  height: 30px;
  position: relative;
  z-index: 1;
}

.top_menu_img {
  width: 100%;
  height: 100%;
}

.top_title {
  width: 99px;
  height: 30px;
}

.top_menu_logo {
  width: 100%;
  height: 100%;
}

.title_text {
  font-size: 18px;
  font-weight: 800;
}

.top_search {
  width: 30px;
  height: 30px;
}

.top_menu_search {
  width: 100%;
  height: 100%;
}

.nav_left {
  background-color: #fff;
  position: fixed;
  /* top: 60px; */
  top: 8.5vh;
  left: -220px;
  width: 220px;
  height: 100%;
  /* height: 100vh; */
  /* height: 91.5vh; */
  -webkit-box-shadow: 15px 15px 15px 15px rgba(0, 0, 0, 0.2);
  box-shadow: 15px 15px 15px 15px rgba(5, 2, 2, 0.2);
  z-index: 10000;
}

.nav_left.active {
  left: 0;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: all 1.5s ease;
  transition: all 1.5s ease;
}

.backactive {
  left: -220px;
  -webkit-transform: translateX(-220px);
  transform: translateX(-220px);
  -webkit-transition: all 1.5s ease;
  transition: all 1.5s ease;
}

.nav_left_item_img {
  width: 100%;
  height: 44px;
  /* margin-left: 20px; */
  margin-top: 10px;
  margin-bottom: 10px;
}

.left_item_logo {
  /* width: 100%; */
  width: 112px;
  height: 34px;
}

.nav_left_item,
.nav_left_item_case {
  width: 160px;
  height: 50px;
  line-height: 50px;
  text-align: left;
  margin: 0 20px;
  padding: 0 10px;
  border-bottom: 1px solid #90a59e;
  color: #000000;
}

.nav_left_item_case.hovers {
  background-color: #dcdcdc;
  color: #000000;
}

.nav_left_item_ul {
  height: auto;
}

.nav_left_item_ul ul {
  height: auto;
  display: block;
}

.nav_left_item_ul ul li {
  width: 160px;
  height: 50px;
  line-height: 50px;
  text-align: left;
  margin: 0 20px;
  padding: 0 10px;
  border-bottom: 1px solid #90a59e;
  color: #000000;
  display: block;
  /* color: #a8a8a8; */
}

/* .nav_left_item_ul ul li:hover {
  background-color: #dcdcdc;
  color: #000000;
} */

.nav_left_item_li {
  height: 50px;
}

.go-top {
  width: 50px;
  height: 50px;
  overflow: hidden;
  position: fixed;
  right: 0;
  bottom: 25%;
  z-index: 1;
}

.top-img {
  width: 50px;
  height: 50px;
}

.search-block {
  width: 100%;
  height: 100%;
  /* height: 91.5vh; */
  position: fixed;
  top: 8.5vh;
  left: 0;
  z-index: 10;
}

.search-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.search-top-block {
  width: 94%;
  padding: 0 3%;
  background-color: #ffffff;
  position: relative;
  z-index: 11;
}

.search-top-type {
  padding-top: 15px;
}

.search-type-item {
  width: 80px;
  /* padding: 0 10px; */
  border: 1px solid #a8a8a8;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  margin-right: 5px;
  border-radius: 25px;
}

.search-type-item.active {
  border: none;
  background-color: #dd262e;
  color: #ffffff;
}

.search-input-block {
  width: 100%;
  height: 50px;
  padding-top: 10px;
  border-bottom: 2px solid #f5f5f5;
}

.search-input-wrap {
  width: 75%;
  height: 30px;
  line-height: 30px;
  border: none;
  font-size: 14px;
}

.search-input-num {
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: right;
}

/* .page-top {
  width: 100%;
  height: 45px;
  line-height: 45px;
  font-size: 18px;
  position: fixed;
  top: 0;
  padding: 10px 20% 0 20%;
  z-index: 999;
}

.top-title {
  cursor: pointer;
  margin-right: 70px;
}

.top-title-noright {
  cursor: pointer;
  margin-right: none;
}

.background {
  background: #ffffff;
}

.tabBottom {
  color: #034b3d;
  border-bottom: 2px solid #034b3d;
} */

.page-footer {
  width: 100%;
  height: 280px;
  padding-bottom: 30px;
  background-color: #034b3d;
}

.footer-left {
  width: 320px;
  color: #ffffff;
  font-size: 14px;
}

.footer-left-title {
  width: 100%;
  height: 35px;
  line-height: 35px;
  text-align: left;
}

.footer-link-title {
  width: 110px;
  height: 35px;
  line-height: 35px;
  text-align: left;
  cursor: pointer;
}

.footer-line {
  width: 1px;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 0 15px;
}

.footer-left-phone {
  width: 100%;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
}

.footer-right {
  margin-left: 40px;
}

.footer-img-block {
  margin-right: 40px;
}

.footer-code {
  width: 80px;
  height: 80px;
}

.footer-img-text {
  margin-top: 5px;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  color: #ffffff;
}

.go-top {
  width: 50px;
  height: 50px;
  overflow: hidden;
  position: fixed;
  right: 0;
  bottom: 25%;
  z-index: 1;
}

.top-img {
  width: 50px;
  height: 50px;
}
</style>
