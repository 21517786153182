<template>
  <div class="indexs" id="indexTop">
    <!-- <div class="top-swiper"> -->
      <img src="../../assets/honor/topbanner.png" alt="" class="banner-img" />
    <!-- </div> -->
    <div class="yearTab"> <div :class="{selected: yearCount == 1 }" @click="year2021" > 2021年</div>
      <div  :class="{selected: yearCount == 2 }"  @click="year2022" > 2022年 </div> </div>

    <div class="honor-content flex flex-direction align-center" id="year2021" v-if="year2021Status" >

      <div class="honor-year">2021年</div>
      <div class="honor-content-wrap flex flex-direction align-center">
        <div class="honor-line"></div>
        <div class="honor-content-tree flex flex-direction justify-center align-center">
          <div class="flex justify-center align-center">
            <div class="honor-content-left flex align-center"></div>
            <div class="honor-center-month">1月8日</div>
            <div class="honor-content-right flex align-center">
              <div class="honor-right-block flex align-center">
                <div class="honor-right-line"></div>
                <div class="honor-right-block-wrap flex justify-center align-center">
                  <div class="honor-right-block-in"></div>
                </div>
              </div>
              <div class="honor-right-one">春之唤品牌荣获中国美都化妆品高质量发展大会“金质奖”</div>
            </div>
          </div>
          
          <div class="flex justify-center align-center" style="margin-top: -60px;">
            <div class="honor-content-left flex align-center">
              <div class="honor-content-left-text-wrap">
                <div class="honor-left-two">春之唤品牌荣获广东315消费维权打假论坛颁发的“放心消费品牌”</div>
                <div class="honor-left-two">中国美妆网授予春之唤品牌“2020中国美妆年度成长品牌”</div>
              </div>
              <div class="honor-left-block-two">
                <div class="honor-left-block-top flex justify-center align-center">
                  <div class="honor-left-block-top-in"></div>
                </div>
                <div class="honor-left-block-bottom flex justify-center align-center">
                  <div class="honor-left-block-top-in"></div>
                </div>
              </div>
            </div>
            <div class="honor-center-month">3月15日</div>
            <div class="honor-content-right"></div>
          </div>

          <div class="flex justify-center align-center" style="margin-top: -120px;">
            <div class="honor-content-left flex align-center"></div>
            <div class="honor-center-month">3月26日</div>
            <div class="honor-content-right flex align-center">
              <div class="honor-right-block-two flex align-center">
                <div class="honor-right-block-top flex justify-center align-center">
                  <div class="honor-right-block-in"></div>
                </div>
                <div class="honor-right-block-bottom flex justify-center align-center">
                  <div class="honor-right-block-in"></div>
                </div>
              </div>
              <div class="honor-content-right-text-wrap">
                <div class="honor-right-two">中国管理科学研究院品牌推进委员会授予春之唤品牌自主生产企业广东尚品汇生物科技有限公司“中国化妆品行业质量可信企业”</div>
                <div class="honor-right-two">中国管理科学研究院品牌推进委员会授予春之唤品牌自主生产企业广东尚品汇生物科技有限公司“中国化妆品行业自主创新品牌”</div>
              </div>
            </div>
          </div>

          <div class="flex justify-center align-center" style="margin-top: -120px;">
            <div class="honor-content-left flex align-center">
              <div class="honor-left-two">广东省企业品牌建设促进会授予春之唤创始人徐晓英女士作为《广东知名品牌评价通则》团体标准参与起草人，获得“推动广东品牌强省建设事业作出重要贡献”荣誉证书</div>
              <div class="honor-left-block">
                <div class="honor-left-block-top flex justify-center align-center">
                  <div class="honor-left-block-top-in"></div>
                </div>
                <div class="honor-left-line"></div>
              </div>
            </div>
            <div class="honor-center-month">5月7日</div>
            <div class="honor-content-right"></div>
          </div>

          <div class="flex justify-center align-center" style="margin-top: -70px;">
            <div class="honor-content-left flex align-center"></div>
            <div class="honor-center-month">5月</div>
            <div class="honor-content-right flex align-center">
              <div class="honor-right-block flex align-center">
                <div class="honor-right-line"></div>
                <div class="honor-right-block-wrap flex justify-center align-center">
                  <div class="honor-right-block-in"></div>
                </div>
              </div>
              <div class="honor-right-one">春之唤品牌荣获品牌强国先进工程“2021广东品牌发展大会合作品牌”称号</div>
            </div>
          </div>

          <div class="flex justify-center align-center" style="margin-top: -60px;">
            <div class="honor-content-left flex align-center">
              <div class="honor-left-two">唐山市路北区教育局授予春之唤品牌自主生产企业广东尚品汇生物科技有限公司“关爱下一代健康成长爱心企业”称号</div>
              <div class="honor-left-block">
                <div class="honor-left-block-top flex justify-center align-center">
                  <div class="honor-left-block-top-in"></div>
                </div>
                <div class="honor-left-line"></div>
              </div>
            </div>
            <div class="honor-center-month">5月16日</div>
            <div class="honor-content-right"></div>
          </div>

          <div class="flex justify-center align-center" style="margin-top: -40px;">
            <div class="honor-content-left flex align-center"></div>
            <div class="honor-center-month">6月20日</div>
            <div class="honor-content-right flex align-center">
              <div class="honor-right-block flex align-center">
                <div class="honor-right-line"></div>
                <div class="honor-right-block-wrap flex justify-center align-center">
                  <div class="honor-right-block-in"></div>
                </div>
              </div>
              <div class="honor-right-one">品牌创始人77英姐荣获快手616星秀主播榜单第一名</div>
            </div>
          </div>

          <div class="flex justify-center align-center" style="margin-top: -40px;">
            <div class="honor-content-left flex align-center">
              <div class="honor-left-two">河南水灾，77英姐紧急捐款10万元用于抗洪救灾</div>
              <div class="honor-left-block">
                <div class="honor-left-block-top flex justify-center align-center">
                  <div class="honor-left-block-top-in"></div>
                </div>
                <div class="honor-left-line"></div>
              </div>
            </div>
            <div class="honor-center-month">7月21日</div>
            <div class="honor-content-right"></div>
          </div>

          <div class="flex justify-center align-center" style="margin-top: -40px;">
            <div class="honor-content-left flex align-center"></div>
            <div class="honor-center-month">9月25日</div>
            <div class="honor-content-right flex align-center">
              <div class="honor-right-block flex align-center">
                <div class="honor-right-line"></div>
                <div class="honor-right-block-wrap flex justify-center align-center">
                  <div class="honor-right-block-in"></div>
                </div>
              </div>
              <div class="honor-right-one">春之唤入选中管院品牌推进委员会重点孵化品牌</div>
            </div>
          </div>

          <div class="flex justify-center align-center" style="margin-bottom: 60px;margin-top:-40px">
            <div class="honor-content-left flex align-center">
              <div class="honor-left-two">春之唤捐款30万元用于山西抗洪救灾</div>
              <div class="honor-left-block">
                <div class="honor-left-block-top flex justify-center align-center">
                  <div class="honor-left-block-top-in"></div>
                </div>
                <div class="honor-left-line"></div>
              </div>
            </div>
            <div class="honor-center-month">10月11日</div>
            <div class="honor-content-right"></div>
          </div>


          <div class="flex justify-center align-center" style="margin-top: -30px;">
            <div class="honor-content-left flex align-center"></div>
            <div class="honor-center-month">11月</div>
            <div class="honor-content-right flex align-center">
              <div class="honor-right-block-two flex align-center">
                <div class="honor-right-block-top flex justify-center align-center">
                  <div class="honor-right-block-in"></div>
                </div>
                <div class="honor-right-block-bottom flex justify-center align-center">
                  <div class="honor-right-block-in"></div>
                </div>
              </div>
              <div class="honor-content-right-text-wrap">
                <div class="honor-right-two" style="margin-bottom:24px;">英姐受邀出席广东省市场监督管理局政府会议。</div>

                <div class="honor-right-two" style="margin-top:24px;">尚品汇集团荣获打假共建单位<br>尚品汇集团荣获品牌重点孵化器单位</div>
              </div>
            </div>
          </div>

          <div class="flex justify-center align-center" style="margin-bottom: 60px;margin-top: 30px;">
            <div class="honor-content-left flex align-center">
              <div class="honor-left-two">春之唤参加广东卫视企业家跨年晚会。</div>
              <div class="honor-left-block">
                <div class="honor-left-block-top flex justify-center align-center">
                  <div class="honor-left-block-top-in"></div>
                </div>
                <div class="honor-left-line"></div>
              </div>
            </div>
            <div class="honor-center-month">12月</div>
            <div class="honor-content-right"></div>
          </div>



        </div>
      </div>
    </div>



    <div class="honor-content flex flex-direction align-center" id="year2022"  v-if="year2022Status">
      <div class="honor-year">2022年</div>
      <div class="honor-content-wrap flex flex-direction align-center" >
        <div class="honor-line"></div>
        <div class="honor-content-tree flex flex-direction justify-center align-center">

          <div class="flex justify-center align-center" style="margin-top: 40px;">
            <div class="honor-content-left flex align-center"></div>
            <div class="honor-center-month">1月</div>
            <div class="honor-content-right flex align-center">
              <div class="honor-right-block flex align-center">
                <div class="honor-right-line"></div>
                <div class="honor-right-block-wrap flex justify-center align-center">
                  <div class="honor-right-block-in"></div>
                </div>
              </div>
              <div class="honor-right-one">春之唤品牌获得品牌强国广东省知名品牌。</div>
            </div>
          </div>

          <div class="flex justify-center align-center" style="margin-top: 10px;">
            <div class="honor-content-left flex align-center">
              <div class="honor-left-two">春之唤品牌受邀参加广东315晚会。</div>
              <div class="honor-left-block">
                <div class="honor-left-block-top flex justify-center align-center">
                  <div class="honor-left-block-top-in"></div>
                </div>
                <div class="honor-left-line"></div>
              </div>
            </div>
            <div class="honor-center-month">3月</div>
            <div class="honor-content-right"></div>
          </div>


          <div class="flex justify-center align-center" style="margin-top: 40px;">
            <div class="honor-content-left flex align-center"></div>
            <div class="honor-center-month">4月</div>
            <div class="honor-content-right flex align-center">
              <div class="honor-right-block flex align-center">
                <div class="honor-right-line"></div>
                <div class="honor-right-block-wrap flex justify-center align-center">
                  <div class="honor-right-block-in"></div>
                </div>
              </div>
              <div class="honor-right-one">石家庄线下美容院万达广场店和天悦城店开业。</div>
            </div>
          </div>


          <div class="flex justify-center align-center" style="margin-top: 10px;">
            <div class="honor-content-left flex align-center">
              <div class="honor-left-two">济宁线下美容院万达广场店和吾悦广场店开业。</div>
              <div class="honor-left-block">
                <div class="honor-left-block-top flex justify-center align-center">
                  <div class="honor-left-block-top-in"></div>
                </div>
                <div class="honor-left-line"></div>
              </div>
            </div>
            <div class="honor-center-month">6月</div>
            <div class="honor-content-right"></div>
          </div>

          <div class="flex justify-center align-center" style="margin-top: 20px;">
            <div class="honor-content-left flex align-center"></div>
            <div class="honor-center-month">9月</div>
            <div class="honor-content-right flex align-center">
              <div class="honor-right-block flex align-center">
                <div class="honor-right-line"></div>
                <div class="honor-right-block-wrap flex justify-center align-center">
                  <div class="honor-right-block-in"></div>
                </div>
              </div>
              <div class="honor-right-one">春之唤入驻快手四周年。<br>春之唤入驻快手平台4周年，从0到3000多万粉丝，变的是数字，不变的是英佳人一直的陪伴，感谢英佳人一直的陪伴和支持</div>
            </div>
          </div>





        </div>



      </div>
    </div>



    <Footer />



  </div>
</template>

<script>
import router from "@/router";
import Footer from "../../components/footer/footerBottom.vue";

export default {
  name: "Honor",
  components: {
    Footer
  },

  data(){
    return {
      year2021Status:false,
      year2022Status:false,
      yearCount:2
    };
  },


  created(){
    this.$emit("isHonor","honor")
    this.year2022Status=true;//默认当前年进入展示
  },

  metaInfo(){
    return {
      title: "春之唤官方网站",
    }
  },

  methods: {
    year2021(){
      this.year2021Status=true;
      this.year2022Status=false;
      this.yearCount =1;
    },
    year2022(){
      this.year2021Status=false;
      this.year2022Status=true;
      this.yearCount =2;
    }
  },

}
</script>

<style scoped>
.indexs {
  width: 100% !important;
  margin-top: 8.5vh;
  height: 91.5vh;
}

.yearTab{
  display: flex;

}
.yearTab div:first-child{
  margin-top: 50px;
  padding: 5px 24px;
  color: #ffffff;
  background: #e3e3e3;
  border-radius: 30px;
  font-size: 24px;
  font-weight: 600;
}
.yearTab div:last-child{
  margin-top: 50px;
  margin-left: 50px;
  padding: 5px 24px;
  color: #ffffff;
  background: #e3e3e3;
  border-radius: 30px;
  font-size: 24px;
  font-weight: 600;
}
.yearTab div.selected{
  background: #034b3d;
}

.top-swiper {
  width: 100%;
  position: relative;
  z-index: 1;
}

.banner-img {
  width: 100%;
  position: relative;
  z-index: 1;
  display: block;
}

.honor-content {
  width: 90%;
  /* height: 100%; */
  margin-top: 50px;
  margin-left: 5%;
}

.honor-year {
  padding: 5px 24px;
  color: #ffffff;
  background: #034b3d;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
}

.honor-content-wrap {
  position: relative;
  margin-top: 10px;
}

.honor-line {
  width: 1px;
  height: 100%;
  background: #a6b7b1;
  position: absolute;
  top: 0;
}

.honor-content-tree {
  width: 100%;
  position: relative;
  z-index: 1;
}

.honor-center-month {
  width: 64px;
  text-align: center;
  height: 22px;
  line-height: 22px;
  border-radius: 50px;
  background: #034b3d;
  color: #ffffff;
  font-size: 12px;
}

.honor-content-left, .honor-content-right {
  width: 140px;
  height: auto;
}

.honor-left-two, .honor-left-one, .honor-right-two, .honor-right-one {
  width: 100px;
  text-align: left;
  background-color: #f7fffc;
  color: #0e0e0e;
  font-size: 12px;
  line-height: 20px;
  border: 1px dashed #6e8a81;
  margin: 10px 0;
  padding: 8px;
}

.honor-right-block {
  width: 36px;
  margin-left: -10px;
  position: relative;
}

.honor-left-block {
  width: 36px;
  margin-right: -10px;
  position: relative;
}

.honor-right-line {
  width: 100%;
  height: 1px;
  background-color: #034b3d;
}

.honor-left-line {
  width: 100%;
  height: 1px;
  background-color: #034b3d;
}

.honor-right-block-wrap {
  width: 16px;
  height: 16px;
  background-color: #a9b9b4;
  position: absolute;
  right: -8px;
  border-radius: 50%;
}

.honor-left-block-two {
  width: 36px;
  height: 120px;
  border-right: 1px solid #034b3d;
  border-top: 1px solid #034b3d;
  border-bottom: 1px solid #034b3d;
  position: relative;
  margin-right: -10px;
}

.honor-right-block-two {
  width: 36px;
  height: 190px;
  border-left: 1px solid #034b3d;
  border-top: 1px solid #034b3d;
  border-bottom: 1px solid #034b3d;
  position: relative;
  margin-left: -10px;
}

.honor-left-block-top {
  width: 16px;
  height: 16px;
  background-color: #a9b9b4;
  position: absolute;
  top: -8px;
  left: -8px;
  border-radius: 50%;
}

.honor-left-block-bottom {
  width: 16px;
  height: 16px;
  background-color: #a9b9b4;
  position: absolute;
  bottom: -8px;
  left: -8px;
  border-radius: 50%;
}

.honor-right-block-top {
  width: 16px;
  height: 16px;
  background-color: #a9b9b4;
  position: absolute;
  top: -8px;
  right: -8px;
  border-radius: 50%;
}

.honor-right-block-bottom {
  width: 16px;
  height: 16px;
  background-color: #a9b9b4;
  position: absolute;
  bottom: -8px;
  right: -8px;
  border-radius: 50%;
}

.honor-left-block-top-in, .honor-right-block-in {
  width: 8px;
  height: 8px;
  background-color: #034b3d;
  border-radius: 50%;
}
</style>