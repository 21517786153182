import Message from './message.js'

let messageInstance

function getMessageInstance () {
  messageInstance = messageInstance || Message.newInstance()
  return messageInstance
}

function notice ({ duration = 3, content = '' }, type) {
  let instance = getMessageInstance()
  instance.add({ duration: duration, content: content }, type)
}

export default {
  baseEntry (options, type) {
    if (!options.content) {
      options = { content: options }
    }
    return notice(options, type)
  },
  info (options) {
    return this.baseEntry(options, 'info')
  },
  error (options) {
    return this.baseEntry(options, 'error')
  },
  warning (options) {
    return this.baseEntry(options, 'warning')
  },
  success (options) {
    return this.baseEntry(options, 'success')
  }
}
