<template>
  <div class="footer">
    <div class="page-footer flex flex-direction align-center">
      <div class="footer-left flex flex-direction align-start">
        <div class="footer-left-title">快捷导航</div>
        <div class="footer-left-link flex flex-direction align-end">
          <div class="footer-left-link-one flex justify-end align-center">
            <div class="footer-link-title" @click="goIndex">首页</div>
            <div class="footer-link-title" @click="goAbout">了解春之唤</div>
            <div class="footer-link-title" @click="goProduce">全系产品</div>
          </div>
          <div class="footer-left-link-one flex justify-end align-center">
            <div class="footer-link-title" @click="goGrass">探索本草嫩肤</div>
            <div class="footer-link-title" @click="goHonor">品牌荣誉</div>
          </div>
        </div>
        <div class="footer-left-phone flex align-center">联系我们：
          <div @click="callPhone1">020-22326226 </div>
          <div style="margin-left: 20px;" @click="callPhone2">400-0666816</div>
        </div>
      </div>
      <!-- <div class="footer-line"></div> -->
      <div class="footer-right flex justify-around align-center">
        <!-- <div class="footer-img-block flex flex-direction align-center">
          <img src="../../assets/home/bottom_01.gif" class="footer-code" alt="" />
          <div class="footer-img-text">美容顾问老师</div>
        </div> -->
        <div class="footer-img-block flex flex-direction align-center">
          <img src="../../assets/home/bottom_02.gif" class="footer-code" alt="" />
          <div class="footer-img-text">春之唤小程序</div>
        </div>
        <div class="footer-img-block flex flex-direction align-center" style="margin-right:0;">
          <img src="../../assets/home/bottom_03.jpg" class="footer-code" alt="" />
          <div class="footer-img-text">公众号</div>
        </div>
      </div>
      <div class="bottom-banquan flex justify-center align-center">
        <div>©Copyright 2021 春之唤</div>
        <div style="margin-left:10px;cursor: pointer;" @click="goOutLink">浙ICP备2021004403号-2</div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: 'bottomFoo',
  props: {
    msg: String
  },

  data() {
    return {
      
    };
  },

  created() {
    // this.goBackTop();
  },

  methods: {
    // 进入首页
    goIndex() {
      router.replace({
        path: "/homePage",
      });
      // this.goBackTop();
    },

    // 进入关于春之唤
    goAbout() {
      router.replace({
        path: "/companyAbout",
      });
      // this.goBackTop();
    },

    // 进入探寻本草嫩肤
    goGrass() {
      router.replace({
        path: "/companyGrass",
      });
      // this.goBackTop();
    },

    // 进入全系产品
    goProduce() {
      router.replace({
        path: "/companyProduce",
      });
      // this.goBackTop();
    },

    // 进入小课堂
    goClass(){
      router.replace({
        path: "/companyClass"
      });
      // this.goBackTop();
    },

    // 进入品牌动态
    goActive() {
      router.replace({
        path: "/companyActivity",
      });
      // this.goBackTop();
    },

    // 进入品牌动态
    goHonor() {
      router.replace({
        path: "/companyHonor",
      });
      // this.goBackTop();
    },

    // 进入会员中心
    goVipCenter(){
      router.replace({
        path: "/companyVip",
      });
      // this.goBackTop();
    },

    // 进入浙江外链官网
    goOutLink(){
      window.location.href = 'https://beian.miit.gov.cn/';
    },

    // 拨打电话1
    callPhone1(){
      window.location.href = 'tel://020-22326226'
    },

    // 拨打电话2
    callPhone2(){
      window.location.href = 'tel://400-0666816'
    },
  },
};
</script>

<style scoped>
.page-footer {
  width: 100% !important;
  padding: 30px 0 40px 0;
  margin-left: 0;
  background-color: #034b3d;
}

.footer-left {
  width: 90%;
  padding: 0 5% 20px 5%;
  color: #ffffff;
  font-size: 14px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.footer-left-title {
  width: 100%;
  height: 35px;
  line-height: 35px;
  text-align: left;
}

.footer-link-title {
  width: 110px;
  height: 35px;
  line-height: 35px;
  text-align: left;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
}

.footer-line {
  width: 1px;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 0 15px;
}

.footer-left-phone {
  width: 100%;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
}

.footer-right {
  /* margin-left: 40px; */
  margin-top: 35px;
  width: 90%;
  padding: 0 5% 20px 5%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.footer-img-block {
  /* margin-right: 30px; */
}

.footer-code {
  width: 80px;
  height: 80px;
}

.footer-img-text {
  margin-top: 5px;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  color: #ffffff;
}

.go-top {
  width: 50px;
  height: 50px;
  overflow: hidden;
  position: fixed;
  right: 0;
  bottom: 25%;
  z-index: 1;
}

.top-img {
  width: 50px;
  height: 50px;
}

.bottom-banquan {
  width: 100%;
  height: 40px;
  font-size: 12px;
  text-align: center;
  line-height: 40px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 15px;
}
</style>
