<template>
  <div class="indexs" id="indexTop">
    <div class="flex flex-direction align-center">
      <img src="../../assets/story/store_tel_01.jpg" alt="" class="banner-img" />
      <div class="index-content flex flex-direction align-center" data-aos="fade-up">
        <!-- <div class="index-bottom-text-title" style="margin-top:40px;">国际原料优势</div>
        <div class="index-bottom-text-title-content" style="margin-bottom:40px;">走访全球原料产地，汇聚全球优质资源，长期与全球国际大牌原料供应商合作。</div> -->
        <div class="index-content-top" data-aos="fade-up">
          <div class="index-top-title">春之唤溯源之旅</div>
          <!-- <div class="index-top-tips">2018年，春之唤品牌源传公元682年《千金翼方》此古法中“道地本草，春季采摘”的核心理念，以此创立本草护肤品牌。并以“北参南卉”等本草植物结合前沿生物科技，再现肌肤年轻之美！</div> -->
          <div >
            <video id="myVideo" class="video-js video-play"  controls preload="auto" data-setup="">
              <source src="https://v.chunzhihuan.cn/%E5%93%81%E7%89%8C%E5%AE%A3%E4%BC%A0/%E6%80%BB%E5%BC%80%E7%AF%87.mp4" type="video/mp4" />
            </video>
          </div>
          <div class="index-top-bottom" style="margin-top: 10px;">早春采根，仲春采叶，暮春采花，科技创新永不止步！</div>
          <div class="index-top-bottom">春之唤 ，唤醒年轻之美！</div> 
        </div>
        <!-- <img src="../../assets/story/store_01.jpg" class="index-content-banner-img" alt=""> -->
        <div class="index-two" data-aos="fade-up">
          <img src="../../assets/story/store_tel_03.jpg" class="index-two-img" alt="">
          <!-- <img src="../../assets/story/store_03.jpg" class="index-two-img" alt=""> -->
          <div class="index-two-text flex flex-direction justify-center">
            <div class="index-two-text-title">本草嫩肤倡导品牌</div>
            <div class="index-two-text-tips">早在公元682年，唐代孙思邈的《千金翼方》中就记载了各种本草植物的种植法，并提出道地本草、季节性采摘的理念，认为植物的品质取决于产地和采摘季节，而大部分本草植物适合在春天采摘。春天万物生长，经历了一个冬季的休眠，此时沐浴暖日春雨，生命力旺盛，此时采摘的本草类植物品质好。</div>
          </div>
        </div>
        <div class="index-two" data-aos="fade-up">
          <img src="../../assets/story/store_tel_04.jpg" class="index-two-img" alt="">
          <div class="index-two-text flex flex-direction justify-center">
            <div class="index-two-text-title">延传古方</div>
            <div class="index-two-text-tips">2018年，这一理念被“春之唤”护肤品牌加以传承，甄选产于春天的本草植物为核心成分，成为以“道地本草，春季采摘”为核心理念的本草护肤品牌。品牌创始人徐晓英女士专研护肤多年，一直立志于用本草原料打造护肤民族品牌，在深入了解了本草植物的产地与采摘理念后，深受启发与触动，于是创立了春之唤护肤品牌，意喻以春之能量唤醒肌肤年轻之美。</div>
          </div>
        </div>
        <div class="index-two" data-aos="fade-up">
          <img src="../../assets/story/store_tel_05.jpg" class="index-two-img" alt="">
          <div class="index-two-text flex flex-direction justify-center">
            <div class="index-two-text-title">品牌理念</div>
            <div class="index-two-text-tips">春之唤品牌创立之后，徐晓英女士发挥自己行业多年的资源优势，在原材料端坚持甄选道地本草，于春季采摘，用现代科技破译本草养肤肌秘，推出专业、科技、活力、轻奢的国货护肤产品。以“北参南卉”等本草植物结合前沿生物科技，唤醒肌肤内在能量，让熟龄肌肤变得水润柔嫩，再现年轻之美。</div>
          </div>
        </div>
        <div class="index-two" data-aos="fade-up">
          <img src="../../assets/story/store_tel_06.jpg" class="index-two-img" alt="">
          <div class="index-two-text flex flex-direction justify-center">
            <div class="index-two-text-title">春之唤国货草本养肤护肤品牌</div>
            <div class="index-two-text-tips">春之唤品牌创立春之唤是一个以本草科技护肤为核心的国货护肤品品牌，2018年由徐晓英女士于中国广州创立。春之唤以“道地本草?春季采摘”为核心理念，甄选产于春天的本草植物为核心成分，用现代科技破译本草养肤肌秘，唤醒肌肤年轻之美。春之唤匠心研发打造产品，并积极履行社会责任，走国货护肤品之路，让国人认可国货品牌。之后，徐晓英女士发挥自己行业多年的资源优势，在吉林、云南等地建立多个本草种植基地，在原材料端坚持“道地本草，春季采摘”，在科研技术上与广州中医药大学、上海交通大学建立联合实验室，用现代科技破译本草密码，推出专业、科技、活力、轻奢的国货护肤精品。以春参、春卉等本草植物结合前沿生物科技，唤醒肌肤内在能量，让熟龄肌肤变得水润柔嫩，再现年轻之美！</div>
          </div>
        </div>
        <div class="index-two" data-aos="fade-up">
          <img src="../../assets/story/store_tel_07.jpg" class="index-two-img" alt="">
          <div class="index-two-text flex flex-direction justify-center">
            <div class="index-two-text-title">春之唤品牌创始人  徐晓英女士</div>
            <div class="index-two-text-tips">快手号：77英姐</div>
            <div class="index-two-text-tips1">企业家、慈善家</div>
            <div class="index-two-text-tips1">春之唤品牌创始人</div>
            <div class="index-two-text-tips1">本草养肤文化传承者</div>
            <div class="index-two-text-tips1">快手官方授予百强主播快手电商年度影响力主播</div>
            <div class="index-two-text-tips1">快手美妆实力宠粉官</div>
            <div class="index-two-text-tips1">《广东知名品牌评价通则》团体标准参与起草人</div>
            <div class="index-two-text-tips1">广东尚品汇生物科技有限公司董事长</div>
            <div class="index-two-text-tips1">曾受邀参加广东电视台诚信企业信用访谈采访</div>
          </div>
        </div>
      </div>
      <div class="index-content flex flex-direction align-center" data-aos="fade-up">
        <div class="index-bottom-text flex flex-direction align-center">
          <div class="index-bottom-text-title">品牌荣誉</div>
          <div class="index-bottom-text-title-content">“春之唤”是广东尚品汇生物科技有限公司旗下品牌，广东尚品汇生物科技有限公司是一家以化妆品研发、生产、销售为一体的生产企业，是一家科技驱动型公司，获得多项荣誉奖项。</div>
          <div class="index-bottom-text-title-content">春之唤品牌一直以来专注匠心品质，以科技融合草本精粹，实力获得了多项行业殊荣：2021中国美都化妆品高质量发展大会“金质奖”，2021广东315消费维权打假论坛颁发的“放心消费品牌”，“中国化妆品行业自主创新品牌”，“2021广东品牌发展大会合作品牌”，“中管院品牌推进委员会重点孵化品牌”等。</div>
          <img src="../../assets/story/store_tel_08.jpg" data-aos="fade-up" style="margin-top: 40px;" class="index-content-banner-bottom-img" alt="">
          <img src="../../assets/story/store_tel_09.jpg" data-aos="fade-up" class="index-content-banner-bottom-img" alt="">
        </div>
      </div>
      <!-- <img src="../../assets/story/store_banner_02.jpg" alt="" class="banner-img" />
      <div class="index-content">
        <div v-for="(item, index) in storyList" :key="index">
          <div class="index-two">
            <div class="index-two-text flex flex-direction justify-center">
              <div class="index-two-text-title" v-html="item.title"></div>
              <div class="index-two-text-tips" v-html="item.content"></div>
            </div>
            <img :src="item.url1" class="index-two-img" alt="">
          </div>
        </div>
        <div class="index-bottom-text flex flex-direction align-center">
          <img src="../../assets/story/store_23.jpg" class="index-content-banner-bottom-img" alt="">
        </div>
      </div> -->
    </div>
    
    <Footer />
  </div>
</template>

<script>
import router from "@/router";
import Footer from "../../components/footer/footerBottom.vue";

export default {
  name: "About",
  components: {
    Footer
  },

  data(){
    return {
      // storyList: [
      //   {
      //     id: 1,
      //     url1: require("../../assets/story/store_07.jpg"),
      //     title: "实验室研发介绍",
      //     content: "<div>与广州中医药大学、上海交大药学院合作，将微生物发酵工程与化妆品应用相结合的研发基地。</div><div style='margin-top:20px;'>与上海交通大学药学院、药学类“985工程”重点院校签署合作并承担众多海外重大重点科研项目，获得超过200项国家发明专利认证。</div>",
      //     // url2: require("../../assets/story/store_08.jpg"),
      //   },
      //   {
      //     id: 2,
      //     url1: require("../../assets/story/store_09.jpg"),
      //     title: "分子晶体破壁提取技术",
      //     content: "恒低温连续提取，多级膜分离和纯化，定量标准拼配，真空低温重组结晶等生产方式，在保证植物天然全成分提取质量的前提下，有效去除农药及重金属残留，使药效成分充分释放，分子晶体提取物更有利于皮肤吸收！",
      //     // url2: require("../../assets/story/store_10.jpg"),
      //   },
      //   {
      //     id: 3,
      //     url1: require("../../assets/story/store_11.jpg"),
      //     title: "本草发酵科技",
      //     content: "春之唤核心技术本草发酵科技是一项创新、绿色、安全、环保的高科技萃取技术，拥有多项发明专利。以源自大自然的天然本草植物以及精心配伍的组方结合生物发酵技术，打造出易于肌肤吸收的本草护肤品。",
      //     // url2: require("../../assets/story/store_12.jpg"),
      //   },
      //   {
      //     id: 4,
      //     url1: require("../../assets/story/store_13.jpg"),
      //     title: "UFD透皮渗透专利技术",
      //     content: "打开肌肤营养通道，让肌肤像海绵般吸收营养成分，形成我们的微美滴、小液滴成分，有效渗透，层层深入肌肤，传递丰沛营养。",
      //     // url2: require("../../assets/story/store_14.jpg"),
      //   },
      //   {
      //     id: 5,
      //     url1: require("../../assets/story/store_15.jpg"),
      //     title: "微美滴传新技术",
      //     content: "助力肌肤吸收，突破性的渗透肌肤技术，源源不断为肌肤输送养分。",
      //     // url2: require("../../assets/story/store_16.jpg"),
      //   },
      //   {
      //     id: 6,
      //     url1: require("../../assets/story/store_17.jpg"),
      //     title: "<div>一种低温微射流法提取人参活性肽</div><div>和人参皂苷浓缩液的方法</div>",
      //     content: "无水配方原料，春之唤自有发酵液，采用酵母杆菌加上人参根提取的发酵液作为主原料，辅料为黑灵芝提取物。低温微射流法提取人参活性肽和人参皂苷浓缩液的方法，渗透技术是正常护肤品的N多倍。",
      //     // url2: require("../../assets/story/store_18.jpg"),
      //   },
      //   {
      //     id: 7,
      //     url1: require("../../assets/story/store_19.jpg"),
      //     title: "<div>人参水蛭素</div><div>发酵液的配置方法</div>",
      //     content: "发酵后获取10倍活性功效的人参酵素。更易被肌肤深层吸收，加速肌肤代谢，让肌肤恢复亮、透、白。",
      //     // url2: require("../../assets/story/store_20.jpg"),
      //   },
      // ],
      title: "",
    }
  },

  created(){
    this.$emit("isAbout","about")
    // this.handleScroll()
  },

  // mounted() {
  //   window.addEventListener("scroll", this.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
  // },

  metaInfo(){
    return {
      title: "春之唤官方网站",
    }
  },

  methods: {
    // 页面滚动
    // handleScroll() {
    //   let scrollTop =
    //     window.pageYOffset ||
    //     document.documentElement.scrollTop ||
    //     document.body.scrollTop; // 滚动条偏移量
    //     console.log(scrollTop)
    //   // if (scrollTop > 30) {
    //   //   this.isbackground = true;
    //   // } else {
    //   //   this.isbackground = false;
    //   // }
    // },
  },
}
</script>

<style scoped>
.indexs {
  width: 100% !important;
  margin-top: 8.5vh;
  /* height: 91.5vh; */
}

.banner-img {
  width: 100%;
  position: relative;
  z-index: 1;
  display: block;
}

.top-mask {
  position: fixed;
  z-index: 12;
  display: none;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000;
}

.index-content {
  width: 90%;
  margin: 20px 0;
  /* padding-bottom: 50px; */
}

.index-content-banner-img {
  width: 100%;
}

.index-content-top {
  width: 100%;
}

.index-top-title {
  font-size: 18px;
  color: #3d847e;
}

.index-top-tips {
  width: 90%;
  text-align: left;
  font-size: 13px;
  margin-top: 10px;
}

.index-top-img {
  width: 100%;
  margin-top: 15px;
}

.index-top-bottom {
  width: 90%;
  text-align: center;
  font-size: 12px;
}

.index-two {
  width: 100%;
  /* height: 430px; */
  margin-top: 20px;
  /* margin-bottom: 20px; */
}

.index-two-img {
  width: 100%;
  display: block;
  /* height: 150px; */
}

.index-two-text {
  width: 100%;
  /* height: 370px;
  padding: 30px; */
}

.bg-green {
  background-color: #ecf2f2;
}

.video-js .vjs-tech {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-play {
  width:100%;
  height: 200px;
  margin-top: 30px;
  position:relative;
}


.index-two-text-title {
  font-size: 18px;
  line-height: 20px;
  margin-top: 15px;
  text-align: left;
  font-weight: 600;
}

.index-two-text-tips {
  margin-top: 15px;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
}

.index-two-text-tips1 {
  font-size: 12px;
  line-height: 20px;
  text-align: left;
}

.index-bottom-text {
  width: 100%;
}

.index-bottom-text-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 15px;
}

.index-bottom-text-title-content {
  font-size: 12px;
  line-height: 20px;
  text-align: left;
}

.index-content-banner-bottom-img {
  width: 100%;
  display: block;
  /* margin: 40px 0; */
}
</style>