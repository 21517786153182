<template>
  <div class="indexs" id="indexTop">
    <div class="flex flex-direction align-center">
      <img src="../../assets/faceTest/test_banner.jpg" alt="" class="banner-img" />
      <img src="../../assets/faceTest/test_01.jpg" alt="" class="content-img" @click="goFace" />
    </div>
    
    <Footer />
  </div>
</template>

<script>
import router from "@/router";
import Footer from "../../components/footer/footerBottom.vue";

export default {
  name: "Face",
  components: {
    Footer
  },

  data(){
    return {
      
    }
  },

  created(){
    this.$emit("isOther","other")
  },

  metaInfo(){
    return {
      title: "春之唤官方网站",
    }
  },

  methods: {
    // 进入皮肤测试外链
    goFace(){
      window.location.href = 'https://api.skinrun.cn/html/ai/index.html?token=pJFDg83DizVEpetamyg49hsrrYZUfDKw%2B%2FqAlwUUPTNfsQwF2zVmdg';
    },
  },
}
</script>

<style scoped>
.indexs {
  width: 100% !important;
  margin-top: 8.5vh;
  height: 91.5vh;
}

.banner-img {
  width: 100%;
  position: relative;
  z-index: 1;
  display: block;
}

.content-img {
  width: 94%;
  margin-top: 15px;
  padding-bottom: 15px;
}
</style>