<template>
  <div class="indexs" id="indexTop">
    <div class="top-swiper">
      <!-- <el-carousel height="230px" :autoplay="true" :interval="2000" loop="true" arrow="never">
        <el-carousel-item v-for="(item, index) in Banner" :key="index">
          <img :src="item.img" alt="" class="banner-img" />
        </el-carousel-item>
      </el-carousel> -->
      <!-- <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in Banner" :key="index" style="height:230px;">
            <img :src="item.img" alt="" class="banner-img" style="height:230px;display:block;" />
          </div>
        </div>
      </div> -->
      <div style="position: relative;">
        <Swiper :options="swiperOption" >
          <SwiperSlide v-for="(item, index) in Banner" :key="index" style="height:230px;">
            <img :src="item.img" alt="" class="banner-img" style="height:230px;display:block;" />
          </SwiperSlide>
        </Swiper>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="index-content flex flex-direction align-center" data-aos="fade-up">
      <img src="../../assets/grass/bencao_tel_03.jpg" class="grass-content-img" alt="">
      <img src="../../assets/grass/bencao_tel_04.jpg" class="grass-content-img" alt="">
      <div class="grass-title flex flex-direction align-center">
        <div class="grass-title-block">专属特色配方</div>
      </div>
      <img src="../../assets/grass/bencao_tel_05.jpg" class="grass-content-imgs" alt="">
      <div class="grass-title flex flex-direction align-center">
        <div class="grass-content-block">春之唤采用本草嫩肤，延传美容养颜古方，</div>
        <div class="grass-content-block">特色成分百倍舒、桃颜舒、谷箱舒、致靓舒等，亲肤温和滋养，</div>
        <div class="grass-content-block" style="margin-bottom:10px;">很好的呵护娇嫩肌肤，带动护肤品营养吸收。</div>
      </div>
    </div>
    <img src="../../assets/grass/bencao_tel_06.jpg" data-aos="fade-up" style="width:100%" alt="">
    <div class="index-contents">
      <div data-aos="fade-up" v-for="(item, index) in storyList" :key="index">
        <div class="index-two">
          <img :src="item.url1" class="index-two-img" alt="">
          <div class="index-two-text flex flex-direction justify-center">
            <div class="index-two-text-title" v-html="item.title"></div>
            <div class="index-two-text-tips" v-html="item.content"></div>
          </div>
        </div>
      </div>
      <img src="../../assets/grass/bencao_tel_13.jpg" class="index-content-banner-bottom-img" data-aos="fade-up" style="margin-top: 40px;margin-bottom: 40px;" alt="">
    </div>
    <Footer />
  </div>
</template>

<script>
import router from "@/router";
import Footer from "../../components/footer/footerBottom.vue";
import { getBannerList } from '@/api';
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "Grass",
  components: {
    Footer,
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      Banner: [],
      swiperOption: {
        loop: true,
        autoplay: {
          running: true
        },
        pagination: {
          el: '.swiper-pagination',
        },
        autoplayDisableOnInteraction: true,
      },
      storyList: [
        {
          id: 1,
          url1: require("../../assets/grass/bencao_tel_07.jpg"),
          title: "分子晶体破壁提取技术",
          content: "恒低温连续提取，多级膜分离和纯化，定量标准拼配，真空低温重组结晶等生产方式，在保证植物天然全成分提取质量的前提下，有效去除农药及重金属残留，使药效成分充分释放，分子晶体提取物更有利于皮肤吸收！",
        },
        {
          id: 2,
          url1: require("../../assets/grass/bencao_tel_08.jpg"),
          title: "本草发酵科技",
          content: "春之唤核心技术本草发酵科技是一项创新、绿色、安全、环保的高科技萃取技术，拥有多项发明专利。以源自大自然的天然本草植物以及精心配伍的组方结合生物发酵技术，打造出易于肌肤吸收的本草护肤品。",
        },
        {
          id: 3,
          url1: require("../../assets/grass/bencao_tel_09.jpg"),
          title: "UFD透皮渗透专利技术",
          content: "打开肌肤营养通道，让肌肤像海绵般吸收营养成分，形成我们的微美滴、小液滴成分，有效渗透，层层深入肌肤，传递丰沛营养。",
        },
        {
          id: 4,
          url1: require("../../assets/grass/bencao_tel_10.jpg"),
          title: "微美滴传新技术",
          content: "助力肌肤吸收，突破性的渗透肌肤技术，源源不断为肌肤输送养分。",
        },
        {
          id: 5,
          url1: require("../../assets/grass/bencao_tel_11.jpg"),
          title: "一种低温微射流法提取人参活性肽和人参皂苷浓缩液的方法",
          content: "无水配方原料，春之唤自有发酵液，采用酵母杆菌加上人参根提取的发酵液作为主原料，辅料为黑灵芝提取物。低温微射流法提取人参活性肽和人参皂苷浓缩液的方法，渗透技术是正常护肤品的N多倍。",
        },
        {
          id: 6,
          url1: require("../../assets/grass/bencao_tel_12.jpg"),
          title: "<div>人参水蛭素</div><div>发酵液的配置方法</div>",
          content: "发酵后获取10倍活性功效的人参酵素。更易被肌肤深层吸收，加速肌肤代谢，让肌肤恢复亮、透、白。",
        },
      ],
    };
  },

  mounted(){
    var mySwiper = new Swiper('.swiper-container',{
      loop: true,
      autoplay:true,//等同于以下设置
        // // 如果需要分页器
        // pagination: '.swiper-pagination',
        // // 如果需要前进后退按钮
        // nextButton: '.swiper-button-next',
        // prevButton: '.swiper-button-prev',
        // // 如果需要滚动条
        // scrollbar: '.swiper-scrollbar',
      pagination: {
        el: '.swiper-pagination',
      },
    })
  },

  created() {
    this.$emit("isOther","other")
    this.getTopBanner()
  },

  metaInfo(){
    return {
      title: "春之唤官方网站",
    }
  },

  methods: {
    // 顶部banner
    async getTopBanner(){
      let data = await getBannerList({
        pos_id: "6"
      });
      this.Banner = data.banner_list;
    },
  },
};
</script>

<style scoped>
.swiper-container {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .swiper-wrapper {
    height: 230px;
  }

  .swiper-slide img {
    max-width: 100%;
  }

  .swiper-slide {
    text-align: center;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

.indexs {
  width: 100% !important;
  margin-top: 8.5vh;
  /* height: 91.5vh; */
}

.top-swiper {
  width: 100%;
  position: relative;
  z-index: 1;
}

.banner-img {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  display: block;
}

.index-content {
  width: 100%;
  /* padding-bottom: 30px; */
}

.grass-content-img {
  width: 100%;
  display: block;
}

.grass-content-imgs {
  width: 90%;
  display: block;
}

.grass-title {
  width: 90%;
  padding: 15px 0;
  position: relative;
}

.grass-title-bg {
  width: 100px;
  position: absolute;
  top: 0;
  left: 0;
}

.grass-title-block {
  width: 100%;
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  font-weight: 600;
  color: #024c3b;
}

.grass-tips-block {
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 10px;
  color: #ffffff;
  background-color: #024c3b;
}

.grass-content-block{
  font-size: 12px;
  line-height: 20px;
}

.index-contents {
  width: 90%;
  margin: 20px 5%;
  /* padding-bottom: 50px; */
}

.index-content-banner-img {
  width: 100%;
}

.index-top-title {
  font-size: 18px;
  color: #3d847e;
}

.index-top-tips {
  width: 90%;
  text-align: left;
  font-size: 13px;
  margin-top: 10px;
}

.index-top-img {
  width: 100%;
  margin-top: 15px;
}

.index-top-bottom {
  width: 90%;
  text-align: center;
  font-size: 12px;
}

.index-two {
  width: 100%;
  /* height: 430px; */
  margin-top: 20px;
  /* margin-bottom: 20px; */
}

.index-two-img {
  width: 100%;
  display: block;
  /* height: 150px; */
}

.index-two-text {
  width: 100%;
  /* height: 370px;
  padding: 30px; */
}

.bg-green {
  background-color: #ecf2f2;
}

.index-two-text-title {
  font-size: 18px;
  line-height: 20px;
  margin-top: 15px;
  text-align: left;
  font-weight: 600;
}

.index-two-text-tips {
  margin-top: 15px;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
}

.index-two-text-tips1 {
  font-size: 12px;
  line-height: 20px;
  text-align: left;
}

.index-bottom-text {
  width: 100%;
}

.index-bottom-text-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 15px;
}

.index-bottom-text-title-content {
  font-size: 12px;
  line-height: 20px;
  text-align: left;
}

.index-content-banner-bottom-img {
  width: 100%;
  display: block;
  /* margin: 40px 0; */
}

.swiper-pagination {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
</style>

<style>
.swiper-pagination-bullet {
  background: #ffffff;
  opacity: 0.5 !important;
  margin: 0 8px;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #ffffff !important;
   opacity: 1 !important;
}
</style>
