<template>
  <div class="indexs" id="indexTop">
    <div class="index-content flex flex-direction align-center">
      <div class="index-search flex justify-end align-center">
        <div class="search-left flex justify-center align-center">
          <div class="search-video" @click="getMoreList('','1')">视频教程</div>
          <div class="search-video" @click="goClassWord">护肤知识</div>
        <!-- </div> -->
        <!-- <div class="search-right flex justify-between align-center"> -->
          <input type="text" class="search-input-wrap" v-model="searchText" placeholder="请输入" maxlength="100" @keyup.enter="searchHandle">
          <div class="search-img-block">
            <img src="../../assets/search.png" class="search-img" @click="searchHandle" alt="">
          </div>
          
        </div>
      </div>
      <div class="content-produce">
        <!-- <video id="my-video" class="video-js video-play" controls preload="auto" poster="" data-setup="{}">
          <source src="../../assets/test_video.mp4" type="video/mp4" />
        </video> -->
        <div style="width:100%" v-if="produceList">
          <video id="myVideo" class="video-js video-play" controls preload="auto" :poster="videoPoster" data-setup=""></video>
        </div>
        <div class="produce-wrap flex flex-wrap justify-between align-center" v-if="produceList">
          <div class="produce-block flex flex-direction align-center" v-for="(item, index) in produceList" :key="index">
            <img :src="item.cover_pic" class="produce-img" alt="">
            <div class="produce-title">{{ item.title }}</div>
            <!-- <div class="produce-title-tips">{{ item.title_tips}}</div> -->
            <div class="go-img-text-block flex justify-center align-center">
              <img src="../../assets/produce/hand.png" class="go-hand-img" alt="">
              <div class="produce-go flex justify-center align-center" @click="goClassVideo(item.url, item.cover_pic, item.title)"><span class="go-top10-info-text">点击学习使用方法</span></div>
            </div>
          </div>
        </div>
        <div class="produce-wrap" style="height:100px;line-height:100px;text-align:center;margin-right:0;" v-else>没有查询到商品</div>
      </div>
      <div class="page-block">
        <el-pagination layout="prev, pager, next" :page-count="pageCount" @current-change="pageCurrentChange" @prev-click="prevPage" @next-click="nextClick"></el-pagination>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import router from "@/router";
import Footer from "../../components/footer/footerBottom.vue";
import { getVideoClass } from '@/api';

export default {
  name: "CompanyClass",
  components: {
    Footer
  },

  data(){
    return {
      searchText: "", // 搜索框内容
      videoSrc: "", // 视频路径
      videoPoster: "", // 视频封面
      pageCount: 0,
      produceList: [],
      title: "",
    }
  },

  mounted() {
    this.initVideo();
  },

  metaInfo(){
    return {
      title: this.title + "_春之唤官方网站",
    }
  },

  created(){
    this.id = this.$route.query.id;
    this.$emit("isVideo","video")
    this.cat_id = 2;
    this.page = 1;
    this.getMoreList();
  },

  methods: {
    initVideo() {
      //初始化视频方法
      let myPlayer = this.$video(myVideo, { 
        controls: true, //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。 
        autoplay: true, //自动播放属性,muted:静音播放
        preload: "auto", //建议浏览器是否应在加载元素后立即开始下载视频数据。
      });
    },

    // 进入教学视频
    goClassVideo(url, poster, title){
      // console.log(url)
      this.videoSrc = url;
      this.videoPoster = poster;
      this.title = title;
      let myPlayer = this.$video(myVideo);
      // console.log(myPlayer);
      myPlayer.src({
        src: this.videoSrc
      })
    },

    // 进入教学文章
    goClassWord(){
      router.replace({
        path: "/classWord"
      })
    },

    // 产品列表
    async getMoreList(searchkeys,pages){
      console.log(searchkeys)
      if(searchkeys) {
        this.searchText = searchkeys
      }
      if(pages) {
        this.searchText = ''
        this.page = 1
      }
      let data = await getVideoClass({
        page: this.page,
        searchkey: this.searchText,
        cat_id: this.cat_id,
        limit: "10",
      })
      this.pageCount = data.total_page;
      this.produceList = data.list;
      this.videoSrc = data.list[0].url;
      this.videoPoster = data.list[0].cover_pic;
      this.title = data.list[0].title;
      let myPlayer = this.$video(myVideo);
      myPlayer.src({
        src: data.list[0].url
      })
    },

    // 回车搜索
    searchHandle(event) {
      if (this.searchText === "") {
        // console.log("null: " + this.searchText)
      } else {
        this.page = 1;
        this.getMoreList();
        // console.log(this.searchText)
      }
    },

    // 页码发生改变时
    pageCurrentChange(val){
      this.page = val;
      this.getMoreList();
    },

    // 上一页
    prevPage(val){
      // console.log(val)
    },

    // 下一页
    nextClick(val){
      // console.log(val)
    },
  },
}
</script>

<style scoped>
.indexs {
  width: 100% !important;
  margin-top: 8.5vh;
  height: 91.5vh;
  background-color: #eafcfc;
}

.banner-img {
  width: 100%;
  position: relative;
  z-index: 1;
  display: block;
}

.index-content {
  width: 100%;
  background-color: #eafcfc;
}

.index-search {
  width: 100%;
  padding: 0 2%;
}

.search-left {
  width: 94%;
  padding: 0 3%;
  height: 45px;
  border-bottom: 2px solid #034b3d;
}

.search-video {
  width: 70px;
  height: 22px;
  text-align: center;
  line-height: 22px;
  font-size: 12px;
  color: #034b3d;
  border: 2px solid #034b3d;
  margin: 0 10px;
}

.search-right {
  height: 30px;
  border-right: 2px solid #a5a6aa;
  margin-left: 40px;
}

.search-input-wrap {
  width: 90px;
  height: 22px;
  line-height: 22px;
  text-align: left;
  padding: 0 10px;
  border: 2px solid #034b3d;
  margin-left: 5px;
}

.search-img-block {
  padding-right: 5px;
  margin-left: 10px;
  border-right: 2px solid #a5a6aa;
}

.search-img {
  width: 25px;
  height: 25px;
  display: block;
  /* margin-left: 10px;
  margin-right: 10px; */
}

.content-produce {
  width: 90%;
}

.video-play {
  width: 100%;
  height: 200px;
  margin-top: 30px;
  position:relative;


}

.produce-wrap {
  width: 100%;
  margin-top: 20px;
}

.produce-block {
  width: 160px;
  margin-bottom: 40px;
}

.produce-img {
  width: 160px;
  height: 160px;
}

.produce-title {
  width: 100%;
  height: 40px;
  line-height: 20px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  margin-top: 5px;
}

.produce-title-tips {
  text-align: left;
  color: #a7a7a7;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
}

.go-img-text-block {
  width: 100%;
  height: 20px;
  margin-top: 8px;
}

.go-hand-img {
  width: 23px;
  height: 20px;
  margin-right: 8px;
}

.produce-go {
  width: 110px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 10px;
  color: #818181;
  border: 1px solid #818181;
  cursor: pointer;
  position: relative;
}

.go-top10-info-text {
  position: relative;
  z-index: 1;
}

.page-block {
  width: 100%;
  height: 40px;
  margin-top: 10px;
  background: none;
}
</style>

<style>
.page-block .el-pagination {
  width: 96% !important;
  display: flex;
  justify-content: center;
  align-content: center;
}

.page-block .el-pagination button {
  background: none;
}

.page-block .el-pager li {
  font-size: 12;
  min-width: 34px;
  background: none;
}

.page-block .el-pagination .btn-prev {
  padding-right: 6px !important;
}

.page-block .el-pagination .btn-next {
  padding-left: 6px !important;
}

.page-block .el-dialog, .el-pager li {
  background: none;
}

.page-block .el-pagination button:disabled {
  background: none;
}
</style>