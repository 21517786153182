import axios from 'axios'
import envConfig from '../config'
import Message from '@Com/Programming/message'
export const baseURL = envConfig[process.env.NODE_ENV || 'development']

const http = axios.create({
  baseURL,
  timeout: 10000,
  headers: {
    common: {
      // 'X-Requested-With': 'XMLHttpRequest',
      // 'token': Cookies.get('token')
    },
    post: {
      // 'Content-Type': 'application/x-www-form-urlencoded'
    }
  }
})

// 请求前的钩子函数
http.interceptors.request.use(function (config) {
  return config
}, function (error) {
  return Promise.reject(error)
})

// 请求后的钩子函数
http.interceptors.response.use(function (res) {
  // Message.error('1111!')
  if(res.data.code === 200) {
    return res.data.data
  } else {
    Message.error(res.data.message)
  // return Promise.reject(error)
  }
}, function (error) {
  Message.error('服务内部错误!')
  return Promise.reject(error)
})

export default http
