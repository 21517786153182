<template>
  <div class="message">
    <div class="message-main" v-for="item in notices" :key="item.name">
      <div :class="'message-content message-' + item.type">
        <i :class="messageType[item.type]"></i>
        {{ item.content }} {{ item.type }}
      </div>
    </div>
  </div>
</template>
<script>

import './message.css'
let seed = 0

function getUuid () {
  return '_Message' + (seed++)
}
export default {
  data () {
    return {
      notices: [],
      messageType: {
        info: 'fa fa-info-circle',
        error: 'fa fa-times-circle',
        warning: 'fa fa-info-circle',
        success: 'fa fa-check-circle'
      }
    }
  },
  methods: {
    add (notice, type) {
      console.log(type)
      const name = getUuid()
      let currentNotice = Object.assign({
        name: name,
        type: type
      }, notice)
      this.notices.push(currentNotice)

      // 定时移除 单位：秒
      const duration = currentNotice.duration
      setTimeout(() => {
        this.remove(name)
      }, duration * 1000)
    },
    remove (name) {
      const notices = this.notices
      for (let i = 0; i < notices.length; i++) {
        if (name === notices[i].name) {
          this.notices.splice(i, 1)
          break
        }
      }
    }
  }
}
</script>
