<template>
  <div class="indexs" id="indexTop">
    <div class="flex flex-direction align-center">
      <img src="../../assets/classWord/word_benner.jpg" alt="" class="banner-img" />
      <div class="activity-block flex flex-direction align-center">
        <div class="activity-wrap flex justify-between align-center" v-for="(item, index) in classList" :key="index" @click="goClassInfo(item.id)">
          <img :src="item.cover_pic" alt="" class="activity-img" />
          <div class="activity-text">
            <div class="activity-title">{{ item.title }}</div>
            <div class="activity-content">{{ item.digest }}</div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import router from "@/router";
import Footer from "../../components/footer/footerBottom.vue";
import { getVideoClass } from '@/api';

export default {
  name: "ClassWord",
  components: {
    Footer
  },

  data(){
    return {
      classList: [],
      pageCount: 0,
      page: 1,
      title: "",
    }
  },

  created(){
    this.$emit("isClass","class")
    this.getMoreList()
  },

  metaInfo(){
    return {
      title: "春之唤官方网站",
    }
  },

  methods: {
    // 品牌动态列表
    async getMoreList(){
      let data = await getVideoClass({
        page: this.page,
        cat_id: 1,
      })
      this.pageCount = data.total_page;
      this.classList = data.list;
    },

    // 进入课堂详情
    goClassInfo(id){
      router.replace({
        path: "/classWordInfo",
        query: { id: id },
      })
    },

    // 页码发生改变时
    pageCurrentChange(val){
      // console.log(val)
      this.page = val;
      this.getMoreList();
    },

    // 上一页
    prevPage(val){
      // console.log(val)
    },

    // // 下一页
    nextClick(val){
      // console.log(val)
    },
  },
}
</script>

<style scoped>
.indexs {
  width: 100% !important;
  margin-top: 8.5vh;
  height: 91.5vh;
}

.banner-img {
  width: 100%;
  position: relative;
  z-index: 1;
  display: block;
}

.activity-block {
  width: 100%;
  padding-bottom: 50px;
  /* padding: 30px; */
}

.activity-wrap {
  width: 90%;
  padding: 30px 0;
  margin-top: 15px;
  border-bottom: 1px solid #d8d8d8;
  cursor: pointer;
}

.activity-img {
  width: 40%;
  display: block;
  /* margin-bottom: 10px; */
}

.activity-text {
  width: 58%;

}

.activity-title {
  width: 100%;
  /* height: 25px; */
  line-height: 25px;
  font-size: 16px;
  text-align: left;
}

.activity-content {
  width: 100%;
  line-height: 20px;
  margin-top: 5px;
  font-size: 12px;
  text-align: left;
  color: #9e9d9d;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>