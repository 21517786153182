<template>
  <div class="indexs" id="indexTop">
    <img src="../../assets/top10/top10_banner.jpg" alt="" class="banner-img" />
    <div class="top10-content flex flex-direction align-center">
      <div class="top10-block">
        <div class="top10-3" v-for="(item, index) in topList1" :key="index" @click="goProduceInfo(item.goods_id)">
          <div class="top10-3-img-block flex justify-between align-center">
            <img :src="item.image_md5" class="top10-3-img" alt="">
            <div class="top10-3-king-num flex flex-direction align-center">
              <img src="../../assets/top10/guan01.png" class="top10-3-king" alt="" v-if="(index === 0)">
              <img src="../../assets/top10/guan02.png" class="top10-3-king" alt="" v-if="(index === 1 || index === 2)">
              <div class="top10-3-num-text" :class="(index+1) == 1 ? 'top-10-first' : ''">0{{ index + 1}}</div>
              <!-- <img src="../../assets/top10/con01.png" class="top10-3-num" alt="" v-if="(item.id === 1)">
              <img src="../../assets/top10/con02.png" class="top10-3-num" alt="" v-if="(item.id === 2)">
              <img src="../../assets/top10/con03.png" class="top10-3-num" alt="" v-if="(item.id === 3)"> -->
            </div>
          </div>
          <div class="top10-3-intro flex flex-direction align-start">
            <div class="top10-3-title">{{ item.goods_title }}</div>
            <div class="top10-3-tips" v-if="item.tips">{{ item.tips }}</div>
            <div class="top10-3-content">{{ item.description }}</div>
            <div class="go-top10-info"><span class="go-top10-info-text">查看详情</span></div>
          </div>
        </div>
        <div class="top10-block-content flex flex-wrap justify-between align-start">
          <div class="top10-7 flex flex-direction align-start" v-for="(item, index) in topList2" :key="index" @click="goProduceInfo(item.goods_id)">
            <img :src="item.image_md5" class="top-7-img" alt="">
            <!-- <img src="../../assets/top10/guan01.png" class="top10-3-king" alt="" v-if="(item.id === 1)"> -->
            <!-- <img src="../../assets/top10/con03.png" class="top10-7-num" alt=""> -->
            <div class="top10-10-num-text">{{ (index + 4) == 10 ? (index+4) : "0"+(index+4)}}</div>
            <div class="top10-10-title">{{ item.goods_title }}</div>
            <div class="top10-3-tips" v-if="item.tips" v-html="item.tips"></div>
            <div class="top10-3-content" v-html="item.description"></div>
            <div class="go-top10-info"><span class="go-top10-info-text">查看详情</span></div>
            <!-- <img :src="item.content_img" class="top-7-img" style="margin-top: 5px;cursor: pointer;" @click="goProduceInfo(item.id)" alt=""> -->
          </div>
        </div>
        <div class="top10-block-content flex flex-wrap justify-between align-center">
          <div class="top10-7 flex flex-direction align-start" style="margin-bottom: 0;" @click="goProduceInfo(topList3[0].goods_id)">
            <img :src="topList3[0].image_md5" class="top-7-img" alt="">
            <!-- <img src="../../assets/top10/guan01.png" class="top10-3-king" alt="" v-if="(item.id === 1)"> -->
            <!-- <img src="../../assets/top10/con03.png" class="top10-7-num" alt=""> -->
            <!-- <div class="top10-10-num-text">10</div> -->
            <!-- <div class="top10-10-title">{{ topList3[0].goods_title }}</div>
            <div class="top10-3-tips" v-if="topList3[0].tips" v-html="topList3[0].tips"></div>
            <div class="top10-3-content" v-html="topList3[0].description"></div>
            <div class="go-top10-info"><span class="go-top10-info-text">查看详情</span></div> -->
            <!-- <img :src="item.content_img" class="top-7-img" style="margin-top: 5px;cursor: pointer;" @click="goProduceInfo(item.id)" alt=""> -->
          </div>
          <div class="top10-7 flex flex-direction align-start" style="margin-bottom: 0;" @click="goProduceInfo(topList3[0].goods_id)">
            <!-- <img :src="topList3[0].image_md5" class="top-7-img" alt=""> -->
            <!-- <img src="../../assets/top10/guan01.png" class="top10-3-king" alt="" v-if="(item.id === 1)"> -->
            <!-- <img src="../../assets/top10/con03.png" class="top10-7-num" alt=""> -->
            <div class="top10-10-num-text">10</div>
            <div class="top10-10-title">{{ topList3[0].goods_title }}</div>
            <div class="top10-3-tips" v-if="topList3[0].tips" v-html="topList3[0].tips"></div>
            <div class="top10-3-content" v-html="topList3[0].description"></div>
            <div class="go-top10-info"><span class="go-top10-info-text">查看详情</span></div>
            <!-- <img :src="item.content_img" class="top-7-img" style="margin-top: 5px;cursor: pointer;" @click="goProduceInfo(item.id)" alt=""> -->
          </div>
        </div>
        <!-- <div class="top10-block-content flex justify-between align-start">
          <div class="top10-10" v-for="(item, index) in topList3" :key="index">
            <img :src="item.img" class="top-10-img" alt="">
            <img src="../../assets/top10/con03.png" class="top10-10-num" alt="">
            <div class="top10-10-title">{{ item.title }}</div>
            <div class="top10-3-tips" v-if="item.tips" v-html="item.tips"></div>
            <div class="top10-3-content" v-html="item.content"></div>
            <div class="go-top10-info" @click="goProduceInfo(item.id)"><span class="go-top10-info-text">查看详情</span></div>
          </div>
        </div> -->
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import router from "@/router";
import Footer from "../../components/footer/footerBottom.vue";
import { getTop10 } from "@/api";

export default {
  name: "Top",
  components: {
    Footer
  },

  data(){
    return {
      topList1: [],
      topList2: [],
      topList3: [],
    }
  },

  created(){
    this.$emit("isOther","other")
    this.getTopList();
  },

  metaInfo(){
    return {
      title: "春之唤官方网站",
    }
  },

  methods: {
    // top10列表
    async getTopList(){
      let data = await getTop10({
        page: this.page,
        is_top: 1,
      })
      console.log(data.list);
      if(data.list.length === 1) {
        this.topList1 = data.list
      } else if(data.list.length === 2) {
        this.topList1 = data.list
      } else if(data.list.length === 3) {
        this.topList1 = data.list
      } else if(data.list.length >= 4) {
        this.topList1 = data.list.slice(0,3);
        this.topList2 = data.list.slice(3,9);
        this.topList3 = data.list.slice(9);
      }
    },

    // 产品详情
    goProduceInfo(id){
      router.replace({
        path: "/companyProduceInfo",
        query: { id: id },
      })
    },
  },
}
</script>

<style scoped>
.indexs {
  width: 100% !important;
  margin-top: 8.5vh;
  height: 91.5vh;
}

.banner-img {
  width: 100%;
  position: relative;
  z-index: 1;
  display: block;
}

.top10-content {
  width: 100%;
  padding-bottom: 30px;
}

.top10-block {
  width: 90%;
  margin-top: 30px;
}

.top10-3 {
  width: 100%;
  margin-bottom: 25px;
}

.top10-3-img-block {
  background-color: #eafcfc;
}

.top10-3-img {
  width: 60%;
  display: block;
}

.top10-3-king-num {
  width: 40%;
}

.top10-3-num {
  width: 80px;
}

.top10-3-num-text {
  width: 80px;
  text-align: center;
  font-size: 70px;
  font-weight: 600;
  color: #c3c3c3;
}

.top10-3-num-text.top-10-first {
  color: #edd281;
}

.top10-3-intro {
  width: 100%;
}

.top10-3-intro-top {
  width: auto;
  position: relative;
}

.top10-3-king {
  width: 70px;
  height: 70px;
  margin-left: 10px;
  /* position: absolute; */
  /* right: 0; */
}

.top10-3-title {
  text-align: left;
  height: 35px;
  line-height: 35px;
  font-size: 18px;
  margin-top: 15px;
  margin-left: 0;
  left: 0;
  font-weight: 600;
}

.top10-3-tips {
  text-align: left;
  font-size: 12px;
}

.top10-3-content {
  text-align: left;
  line-height: 20px;
  font-size: 12px;
  margin-top: 15px;
}

.go-top10-info {
  width: 100px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  color: #424043;
  border: 1px solid #424043;
  margin-top: 25px;
  cursor: pointer;
  /* transition: color 0.3s; */
  background: 0 0;
  position: relative;
}

.go-top10-info:after{
  position: absolute;
  content: '';
  height: 30px;
  width: 0;
  left: 0;
  top: 0;
  background: #034c3b;
  /* transition: width 0.3s; */
}

.go-top10-info-text {
  position: relative;
  z-index: 1;
}

.go-top10-info:hover {
  color: #ffffff;
}

.go-top10-info:hover:after {
  width: 100px;
}

/* transition: color .3s;
    transition-property: color;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s; */

.top10-block-content {
  width: 100%;
}

.top10-7 {
  width: 48%;
  margin-bottom: 20px;
}

.top-7-img {
  display: block;
  width: 100%;
}

.top10-7-num {
  width: 60px;
}

.top10-10-num-text {
  width: 60px;
  text-align: center;
  font-size: 50px;
  color: #cde1df;
  font-weight: 600;
}

.top10-10-title {
  text-align: left;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  margin-left: 0;
  left: 0;
  font-weight: 600;
}

.top10-10 {
  width: 32.5%;
}

.top10-10-num {
  width: 60px;
}

.top-10-img {
  display: block;
  width: 100%;
}
</style>