import Vue from 'vue'
import Message from './message.vue'
/**
 * 虽然 message.vue 包含了 template、script、style 三个标签，并不是一个 JS 对象，
 * 那怎么能够给它扩展一个方法 newInstance 呢？事实上，message.vue 会被
 * Webpack 的 vue-loader 编译，把 template 编译为 Render 函数，最终就会成为一个 JS 对象，自然可以对它进行扩展。
 */

// 给Message 组件添加一个方法
Message.newInstance = properties => {
  const props = properties || {}
  const Instance = new Vue({
    data: props,
    render (h) {
      return h(Message, {
        props: props
      })
    }
  })

  const component = Instance.$mount()
  document.body.appendChild(component.$el)
  const msg = Instance.$children[0]

  return {
    // 这里的 add 和 remove 可不是 message.vue 里的 add 和 remove，它们只是名字一样。
    add (noticeProps, type) {
      msg.add(noticeProps, type)
    },
    remove (name) {
      msg.remove(name)
    }
  }
}

export default Message
