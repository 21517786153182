import Vue from 'vue'
import App from './App.vue'
import router from './router'
import http from 'axios'
import Meta from 'vue-meta'
import './css/style.css'
import {Carousel, CarouselItem, Menu, Submenu, MenuItem, Pagination} from 'element-ui';
// import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video

import AOS from 'aos'
import 'aos/dist/aos.css'

Vue.config.productionTip = false
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Pagination);
Vue.use(AOS);
Vue.use(Meta);
// Vue.use(ElementUI);

AOS.init({
  once: false,
  duration: 1000, // values from 0 to 3000, with step 50ms
});

/**
 * @description 全局注册应用配置
 */
Vue.prototype.$http = http // 全局注册，使用方法为:this.$axios
Vue.filter('slewTime', function (value, type) {
  if (!value) return ''
  return getDate(value, type) //eslint-disable-line no-undef
})
/* eslint-disable*/
Array.prototype.rePush = function (value) {
  let arr = []
  if (!isArray(value)) {
    arr.push(...this, value)
  } else {
    arr.push(...this, ...value)
  }
  return arr
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
