<template>
  <div class="indexs" id="indexTop">
    <div class="flex flex-direction align-center">
      <img src="../../assets/activity/gongyi_banner.jpg" alt="" class="banner-img" />
      <div class="activity-block flex flex-direction align-center">
        <div class="activity-wrap" v-for="(item, index) in storyList" :key="index" @click="goDuty(item.id)">
          <img :src="item.cover_pic" alt="" class="activity-img" />
          <div class="activity-text">
            <div class="activity-title">{{ item.title }}</div>
            <!-- <div class="activity-title" v-if="item.title_tips">{{ item.title_tips }}</div> -->
            <div class="activity-content">{{ item.digest }}</div>
          </div>
        </div>
      </div>
      <div class="page-block">
        <el-pagination layout="prev, pager, next" :page-count="pageCount" @current-change="pageCurrentChange" @prev-click="prevPage" @next-click="nextClick"></el-pagination>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import router from "@/router";
import Footer from "../../components/footer/footerBottom.vue";
import { getNewsList } from '@/api';

export default {
  name: "Active",
  components: {
    Footer
  },

  data(){
    return {
      storyList: [],
      cat_id: 0,
      pageCount: 0,
      page: 1,
      title: "",
    }
  },

  created(){
    this.$emit("isActive","active")
    // this.page = 1;
    this.getMoreList(); 
  },

  metaInfo(){
    return {
      title: "春之唤官方网站",
    }
  },

  methods: {
    // 进入企业社会责任
    goDuty(id){
      router.replace({
        path: "/companyActiveInfo",
        query: { id: id },
      });
    },

    // 品牌动态列表
    async getMoreList(){
      let data = await getNewsList({
        page: this.page,
      })
      this.pageCount = data.total_page;
      this.storyList = data.list;
    },

    // 页码发生改变时
    pageCurrentChange(val){
      // console.log(val)
      this.page = val;
      this.getMoreList();
    },

    // 上一页
    prevPage(val){
      // console.log(val)
    },

    // // 下一页
    nextClick(val){
      // console.log(val)
    },
  },
}
</script>

<style scoped>
.indexs {
  width: 100% !important;
  margin-top: 8.5vh;
  height: 91.5vh;
}

.banner-img {
  width: 100%;
  position: relative;
  z-index: 1;
  display: block;
}

.activity-block {
  width: 85%;
  /* margin-top: 30px; */
  /* width: 65%; */
  padding-bottom: 50px;
  /* padding: 30px; */
}

.activity-wrap {
  /* width: 150px; */
  width: 100%;
  /* height: 120px; */
  padding: 30px 0;
  margin-top: 15px;
  border-bottom: 1px solid #d8d8d8;
  cursor: pointer;
}

.activity-img {
  width: 100%;
  display: block;
  /* margin-bottom: 10px; */
}

.activity-text {
  width: 100%;

}

.activity-title {
  width: 100%;
  /* height: 50px; */
  line-height: 25px;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  margin-top: 15px;
  
}

.activity-content {
  width: 100%;
  line-height: 20px;
  font-size: 14px;
  text-align: left;
  color: #9e9d9d;
  margin-top: 5px;
}
</style>