import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'
import Home from '../views/pages/homePage.vue'
import About from '../views/pages/companyAbout.vue'
import Grass from '../views/pages/companyGrass.vue'
import Produce from '../views/pages/companyProduce.vue'
import Activity from '../views/pages/companyActivity.vue'
import Top from '../views/pages/companyTop10.vue'
import Duty from '../views/pages/companyActiveInfo.vue'
import CompanyClass from '../views/pages/companyClass.vue'
import ClassWord from "../views/pages/classWord.vue"
import companyVIP from '../views/pages/companyVip.vue'
import ProduceInfo from '../views/pages/companyProduceInfo.vue'
import Face from '../views/pages/faceTest.vue'
import WordInfo from "../views/pages/classWordInfo.vue"
import Honor from "../views/pages/companyHonor.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    redirect: "/homePage",
    children:[
      {
        path: '/homePage',
        name: 'Home',
        component: Home,
        meta: {
          keepAlive: false, //该字段表示该页面需要缓存
          isBack: false, //用于判断上一个页面是哪个
        },
      },
      {
        path: '/companyAbout',
        name: 'About',
        component: About,
        meta: {
          keepAlive: false, //该字段表示该页面需要缓存
          isBack: false, //用于判断上一个页面是哪个
        },
      },
      {
        path: '/companyGrass',
        name: 'Grass',
        component: Grass,
        meta: {
          keepAlive: false, //该字段表示该页面需要缓存
          isBack: false, //用于判断上一个页面是哪个
        },
      },
      {
        path: '/companyProduce',
        name: 'Produce',
        component: Produce,
        meta: {
          keepAlive: true, //该字段表示该页面需要缓存
          isBack: false, //用于判断上一个页面是哪个
        },
      },
      {
        path: '/companyActivity',
        name: 'Activity',
        component: Activity,
        meta: {
          keepAlive: true, //该字段表示该页面需要缓存
          isBack: false, //用于判断上一个页面是哪个
        },
      },
      {
        path: '/companyTop10',
        name: 'Top',
        component: Top,
        meta: {
          keepAlive: false, //该字段表示该页面需要缓存
          isBack: false, //用于判断上一个页面是哪个
        },
      },
      {
        path: '/companyActiveInfo',
        name: 'Duty',
        component: Duty,
        meta: {
          keepAlive: false, //该字段表示该页面需要缓存
          isBack: false, //用于判断上一个页面是哪个
        },
      },
      {
        path: '/companyClass',
        name: 'CompanyClass',
        component: CompanyClass,
        meta: {
          keepAlive: true, //该字段表示该页面需要缓存
          isBack: false, //用于判断上一个页面是哪个
        },
      },
      {
        path: '/classWord',
        name: 'ClassWord',
        component: ClassWord,
        meta: {
          keepAlive: true, //该字段表示该页面需要缓存
          isBack: false, //用于判断上一个页面是哪个
        },
      },
      {
        path: '/companyVip',
        name: 'companyVIP',
        component: companyVIP,
        meta: {
          keepAlive: false, //该字段表示该页面需要缓存
          isBack: false, //用于判断上一个页面是哪个
        },
      },
      {
        path: '/companyProduceInfo',
        name: 'ProduceInfo',
        component: ProduceInfo,
        meta: {
          keepAlive: false, //该字段表示该页面需要缓存
          isBack: false, //用于判断上一个页面是哪个
        },
      },
      {
        path: '/faceTest',
        name: 'Face',
        component: Face,
        meta: {
          keepAlive: false, //该字段表示该页面需要缓存
          isBack: false, //用于判断上一个页面是哪个
        },
      },
      {
        path: '/classWordInfo',
        name: 'WordInfo',
        component: WordInfo,
        meta: {
          keepAlive: false, //该字段表示该页面需要缓存
          isBack: false, //用于判断上一个页面是哪个
        },
      },
      {
        path: '/companyHonor',
        name: 'Honor',
        component: Honor,
        meta: {
          keepAlive: false, //该字段表示该页面需要缓存
          isBack: false, //用于判断上一个页面是哪个
        },
      },
    ],
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace //eslint-disable-line no-unused-vars
VueRouter.prototype.replace = function replace(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
